import { types } from 'mobx-state-tree'
import VenueEndModel from './VenueEndModel'
import VenueFieldDimensionsModel from './VenueFieldDimensionsModel'
import { v4 as uuidV4 } from 'uuid'

export const VenueModel = types.model('VenueModel', {
  id: types.optional(types.string, uuidV4()),
  fullName: types.maybeNull(types.optional(types.string, '')),
  name: types.maybeNull(types.optional(types.string, '')),
  knownAs: types.maybeNull(types.string),
  venueEnds: types.array(VenueEndModel),
  addressId: types.optional(types.string, ''),
  fieldDimensions: types.maybeNull(VenueFieldDimensionsModel),
})

export default VenueModel
