import { theme } from '@chakra-ui/react'

const ourTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    nrr: {
      blue: {
        main: '#0087D8',
        50: '#d9f7ff',
        100: '#ace3ff',
        200: '#7bcfff',
        300: '#49bcff',
        400: '#0087D8', // main
        500: '#008fe6',
        600: '#0070b4',
        700: '#005082',
        800: '#003051',
        900: '#001121',
      },
      yellow: {
        main: '#FFD846',
        50: '#fffadb',
        100: '#ffefaf',
        200: '#ffe47e',
        300: '#FFD846',
        400: '#FFD846',
        500: '#FFD846',
        600: '#ffcf1e',
        700: '#e6b507',
        800: '#b38d00',
        900: '#806500',
      },
      black: '#434A54',
      textGray: '#9B9B9B',
      backgroundGray: '#EFEFEF',
      gray: {
        100: '#dcd8d9',
        200: '#bfbfbf', // dark
        300: '#a6a6a6',
        400: '#8c8c8c',
        500: '#737373',
        600: '#595959',
        700: '#404040',
        800: '#282626',
        900: '#150a0d',
      },
      white: {
        main: '#FFFFFF',
        100: '#FFFFFF',
        200: '#FFFFFF',
        300: '#FFFFFF',
        400: '#FFFFFF',
        500: '#FFFFFF',
        600: '#EFEFEF', // dark
        700: '#EFEFEF',
        800: '#EFEFEF',
        900: '#EFEFEF',
      },
      negativeRed: '#bc1c1c',
      positiveGreen: '#4EAA38',
    },
  },
  shadows: {
    ...theme.shadows,
    nrr: {
      light: '0 1px 1px 0 rgba(0, 0, 0, 0.3)',
      dark: '0 1px 1px 0 rgba(67, 74, 84, 0.3)',
    },
  },
  fonts: {
    body: 'Source Sans Pro, sans-serif',
    heading: 'Source Sans Pro, sans-serif',
    mono: theme.fonts.mono,
  },
  fontSizes: {
    xs: '10px',
    sm: '12px',
    md: '14px',
    lg: '16px',
    xl: '18px',
    '2xl': '20px',
    '3xl': '24px',
    '4xl': '32px',
    '5xl': '44px',
    '6xl': '60px',
  },
  columnStyle: {
    backgroundColor: 'nrr.backgroundGray',
    borderBottom: 'solid 1px',
    borderBottomColor: 'nrr.textGray',
    paddingX: '4px',
    height: '100%',
    fontSize: ['sm', 'sm', 'md', 'lg'],
  },
  rowStyle: {
    borderBottom: 'solid 1px',
    borderBottomColor: 'nrr.backgroundGray',
    paddingY: '14px',
  },
  cellStyle: {
    paddingX: ['2px', '4px'],
    fontSize: ['sm', 'sm', 'md', 'lg'],
  },
  cellInnerStyle: {
    width: '100%',
  },
  formElementCoreStyle: {
    height: '40px',
    fontSize: ['sm', 'sm', 'md'],
  },
  formElementCoreStyleSmall: {
    height: '34px',
  },
  headingStyle: {
    marginY: '7px',
  },
  competitions: [
    {
      id: 'cea0b2e7-985c-47d7-8539-b3c53882b761',
      name: 'Testing Comp (TEST)',
      enviro: 'TEST',
      active: true,
      config: {
        winPt: '2',
        tiePt: '1',
        nrPt: '1',
      },
      primaryColor: '#10044a',
      primaryDarkColor: '#070221',
      headingTextColor: '#FFFFFF',
    },
    {
      id: 'a1b492b0-6eb5-434a-a84c-f4d3a9b683b1',
      name: 'Testing Comp (UAT)',
      enviro: 'UAT',
      active: true,
      config: {
        winPt: '2',
        tiePt: '1',
        nrPt: '1',
      },
      primaryColor: '#1b1d41',
      primaryDarkColor: '#0b0c24',
      headingTextColor: '#FFFFFF',
    },
    {
      id: '4430ecf3-5323-4e0f-b687-362121fb326f',
      name: "Men's CPL T20 2024",
      enviro: 'PROD',
      active: true,
      config: {
        winPt: '2',
        tiePt: '1',
        nrPt: '1',
      },
      primaryColor: '#1b1d41',
      primaryDarkColor: '#0b0c24',
      headingTextColor: '#FFFFFF',
    },
    {
      id: '26270ab5-ac9b-4052-a283-f91a9ef02951',
      name: "Women's CPL T20 2024",
      enviro: 'PROD',
      active: true,
      config: {
        winPt: '2',
        tiePt: '1',
        nrPt: '1',
      },
      primaryColor: '#d00111',
      primaryDarkColor: '#a30d19',
      headingTextColor: '#FFFFFF',
    },
  ],
}

export default ourTheme
