import { BrowserRouter as Router, Switch, Route, Redirect, Link } from 'react-router-dom'
import { Flex, Button, Heading } from '@chakra-ui/react'
import { StarIcon } from '@chakra-ui/icons'
import Theme from './theme/theme'
import { ThemeProps } from './types/props'
import { Calc } from './screens/Calc'
import { Header } from './components/Header/Header'

export const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Redirect exact from="/competition/" to="/" />
        <Route exact path="/">
          <Flex w="100%">
            <Flex flex={1} direction="column" alignItems="center">
              <Header />
              <Flex
                w="100%"
                h={['calc(100vh - 170px)', 'calc(100vh - 130px)']}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Flex direction="row" alignItems="center">
                  <StarIcon w={4} h={4} />
                  <Heading as="h2" size="md" ml="7px" py="21px">
                    Active Competitions
                  </Heading>
                </Flex>
                {Theme.competitions
                  .filter((b: ThemeProps) => {
                    return b.active && b.enviro === process.env.REACT_APP_ENV
                  })
                  .map((c: ThemeProps) => {
                    return (
                      <Link key={`compList${c.id}`} to={`/competition/${c.id}`}>
                        <Button
                          w={['90vw', '80vw', '60vw', '40vw']}
                          backgroundColor={c.primaryColor}
                          _hover={{ backgroundColor: c.primaryDarkColor }}
                          color={c.headingTextColor || '#FFFFFF'}
                          py="7px"
                          mb="7px"
                        >
                          {c.name}
                        </Button>
                      </Link>
                    )
                  })}
              </Flex>
            </Flex>
          </Flex>
        </Route>
        <Route path="/competition/:id?">
          <Flex w="100%">
            <Flex flex={1} direction="column">
              <Header />
              <Flex flex={1} padding={['0px', '14px']}>
                <Calc />
              </Flex>
            </Flex>
          </Flex>
        </Route>
      </Switch>
    </Router>
  )
}
