import { types } from 'mobx-state-tree'
import PersonModel from '../../person/PersonModel'

export const BaseMatchOfficialModel = types.model('OfficialOfficialModel', {
  id: types.string,
  person: types.maybeNull(PersonModel),
  selected: false,
})

export default BaseMatchOfficialModel
