import { flow, types } from 'mobx-state-tree'
import { RequestHandler } from '../../api/RequestHandler'
import MatchDatesModel from '../match/MatchDates/MatchDates'
import VenueModel from '../match/Venue/VenueModel'
import MatchConfigsModel from '../match/MatchConfigs/MatchConfigsModel'
import MatchOfficialModel from '../match/MatchOfficial/MatchOfficialModel'
import MatchTeamModel from '../match/MatchTeam/MatchTeam'
import { generateAuthString } from '../../../helpers/generalHelpers'
import { orderBy } from 'lodash'

export const FixtureCompetitionMatchesModel = types
  .model('FixtureCompetitionMatchesModel', {
    id: types.identifier,
    title: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    matchStatusId: types.maybeNull(types.number),
    matchClassId: types.maybeNull(types.number),
    matchFormatId: types.maybeNull(types.number),
    matchNumber: types.maybeNull(types.number),
    matchDates: types.array(MatchDatesModel),
    venue: VenueModel,
    timeZone: types.maybeNull(types.string),
    matchTeams: types.array(MatchTeamModel),
    matchConfigs: MatchConfigsModel,
    matchOfficials: types.maybeNull(types.array(MatchOfficialModel)),
    // NRR calc specific properties below
    dls: types.maybeNull(types.boolean),
    noResult: types.maybeNull(types.boolean),
  })
  .preProcessSnapshot(sn => {
    if (!sn) return sn
    if (!sn.matchTeams) return sn
    if (sn.matchTeams.length > 0) {
      sn.matchTeams[0].maxOvers = sn.matchConfigs.maxOvers?.toString() || '20'
    }
    if (sn.matchTeams.length > 1) {
      sn.matchTeams[1].maxOvers = sn.matchConfigs.maxOvers?.toString() || '20'
      sn.matchTeams = orderBy(sn.matchTeams, ['isHome'], ['desc'])
    }
    return sn
  })
  .actions(self => {
    const setDls = (value: boolean) => {
      self.dls = value
    }
    const setNoResult = (value: boolean) => {
      self.noResult = value
    }
    return {
      setDls,
      setNoResult,
    }
  })

const FixtureCompetitionPoolsModel = types.model('FixtureCompetitionPoolsModel', {
  id: types.string,
  name: types.string,
  order: types.number,
  matches: types.array(FixtureCompetitionMatchesModel),
})

export const FixtureCompetitionStageModel = types.model('FixtureCompetitionStageModel', {
  id: types.string,
  name: types.string,
  order: types.number,
  competitionPools: types.maybeNull(types.array(FixtureCompetitionPoolsModel)),
  matches: types.maybeNull(types.array(FixtureCompetitionMatchesModel)),
})

const FixtureModel = types.model('FixtureModel', {
  competitionId: types.string,
  name: types.string,
  competitionGenderId: types.number,
  competitionAgeGroupId: types.number,
  isUnofficial: types.boolean,
  competitionStages: types.array(FixtureCompetitionStageModel),
})

const FixturesMap = types.model('FixturesMap', {
  id: types.identifier,
  fixture: FixtureModel,
})

const FixturesStore = types
  .model('FixturesStore', {
    results: types.map(FixturesMap),
    state: types.enumeration('State', ['pending', 'done', 'error']),
  })
  .actions(self => {
    const getFixture = (competitionId?: string) => {
      if (!competitionId) return
      const data = self.results.get(competitionId)
      if (data) {
        return data
      }
      if (self.state === 'pending') {
        return null
      }
      fetchFixture(competitionId)
      return
    }

    const fetchFixture = flow(function* fetchBalls(competitionId: string) {
      self.state = 'pending'
      const method = 'GET'
      const url = `${process.env.REACT_APP_API_URL}public/fixture/${competitionId}`
      // make request
      try {
        const response = yield RequestHandler({
          method,
          url,
          headers: { Authorization: generateAuthString(competitionId) },
        })
        if (response && response.ok) {
          const data = yield response.json()
          if (data) {
            self.results.put(
              FixturesMap.create({
                id: competitionId,
                fixture: data,
              })
            )
          }
          self.state = 'done'
        } else {
          self.state = 'done'
        }
      } catch (error) {
        console.warn('Error getting Fixture', error) // eslint-disable-line no-console
        self.state = 'error'
      }
    })

    return {
      getFixture,
      fetchFixture,
    }
  })

export default FixturesStore
