import { types } from 'mobx-state-tree'

const MatchTeamCore = {
  id: types.string,
  isHome: types.boolean,
  teamId: types.string,
  name: types.string,
  shortName: types.maybeNull(types.string),
}

export default MatchTeamCore
