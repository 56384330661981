import { RequestHandlerPropTypes } from '../../types/props'

// All API requests should be handled here, so we have consistent error handling
export const RequestHandler = async (props: RequestHandlerPropTypes) => {
  const { method, url, headers, params, body, timeout = 30000 } = props
  const defaultHeaders = {
    'Content-Type': 'application/json',
  }

  const urlObject = new URL(url)
  if (params) {
    Object.keys(params).forEach(key => urlObject.searchParams.append(key, params[key]))
  }

  let request: RequestInit
  if (method === 'GET') {
    request = {
      headers: Object.assign({}, defaultHeaders, headers),
    }
  } else {
    request = {
      method,
      headers: Object.assign({}, defaultHeaders, headers),
      body: JSON.stringify(body),
    }
  }

  return await Promise.race([
    fetch(urlObject.href, request),
    new Promise((_, reject) => setTimeout(() => reject(new Error(`Timeout: ${url}`)), timeout)), // 30 seconds before timeout
  ])
}
