import { createContext, useContext } from 'react'
import { flow, types } from 'mobx-state-tree'
import FixturesStore from './fixtures/FixturesStore'
import LaddersStore from './ladders/LaddersStore'

export const RootModel = types
  .model('RootModel', {
    initialised: false,
    fixtures: types.optional(FixturesStore, { results: {}, state: 'done' }),
    ladders: types.optional(LaddersStore, { results: {}, state: 'done' }),
  })
  .actions(self => {
    const initialiseStore = flow(function* initialiseStore() {
      self.initialised = true
    })

    return {
      initialiseStore,
    }
  })

const rootStore = RootModel.create()
rootStore.initialiseStore()
const references = new Map()
const appStateMap = new Map()

export const appContext = {
  store: rootStore,
  refs: references,
  appState: appStateMap,
}

export const RootStoreContext = createContext()

export const Provider = RootStoreContext.Provider

// shortcut hook to access the MST store
export function useMst() {
  const { store } = useContext(RootStoreContext)
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider')
  }
  return store
}

// shortcut hook to access the ref's
export function useRefs() {
  const { refs } = useContext(RootStoreContext)
  return refs
}

// shortcut hook to access the modalComponent
export function useAppState() {
  const { appState } = useContext(RootStoreContext)
  return appState
}
