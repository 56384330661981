import { isNil } from 'lodash'
import { flow, types } from 'mobx-state-tree'
import { v4 as uuidV4 } from 'uuid'
import { generateAuthString } from '../../../helpers/generalHelpers'
import { RequestHandler } from '../../api/RequestHandler'

const LadderCompetitionLadderTeamDatumModel = types
  .model('LadderCompetitionLadderTeamDatumModel', {
    value: types.number,
    displayValue: types.string,
    ladderMetricId: types.maybeNull(types.number),
  })
  .preProcessSnapshot(sn => {
    if (!sn) return sn
    if (isNil(sn.value)) sn.value = 0
    if (isNil(sn.displayValue)) sn.displayValue = '0'
    return sn
  })

export const LadderCompetitionLadderTeamModel = types
  .model('LadderCompetitionLadderTeamModel', {
    competitionTeamId: types.maybeNull(types.string),
    teamId: types.maybeNull(types.string),
    rank: types.number,
    teamName: types.maybeNull(types.string),
    ladderTeamData: types.array(LadderCompetitionLadderTeamDatumModel),
    // NRR calc specific properties below
    hasModified: false,
    hasModifiedRank: '',
  })
  .preProcessSnapshot(sn => {
    if (!sn) return sn
    if (isNil(sn.rank)) sn.rank = 1
    return sn
  })

export const LadderCompetitionLadderModel = types
  .model('LadderCompetitionLadderModel', {
    id: types.string,
    name: types.maybeNull(types.string),
    order: types.number,
    competitionLadderId: types.maybeNull(types.string),
    ladderTeams: types.array(LadderCompetitionLadderTeamModel),
  })
  .preProcessSnapshot(sn => {
    if (!sn) return sn
    if (isNil(sn.id)) sn.id = uuidV4()
    if (isNil(sn.order)) sn.order = 1
    return sn
  })

export const LadderCompetitionLaddersColumnsModel = types.model('LadderCompetitionLaddersColumnsModel', {
  ladderMetricId: types.number,
  title: types.string,
  order: types.number,
})

export const LadderCompetitionLaddersModel = types.model('LadderCompetitionLaddersModel', {
  id: types.string,
  ladderColumns: types.array(LadderCompetitionLaddersColumnsModel),
  ladders: types.maybeNull(types.array(LadderCompetitionLadderModel)),
})

export const LadderCompetitionPoolsModel = types.model('LadderCompetitionPoolsModel', {
  id: types.string,
  name: types.string,
  order: types.number,
  ladders: types.array(LadderCompetitionLadderModel),
})

export const LadderCompetitionStageModel = types.model('LadderCompetitionStageModel', {
  id: types.string,
  name: types.string,
  order: types.number,
  hasLadder: types.boolean,
  competitionPools: types.maybeNull(types.array(LadderCompetitionPoolsModel)),
  competitionLadders: types.array(LadderCompetitionLaddersModel),
})

const LadderModel = types.model('LadderModel', {
  competitionId: types.string,
  name: types.string,
  competitionStages: types.array(LadderCompetitionStageModel),
})

const LaddersMap = types.model('LaddersMap', {
  id: types.identifier,
  ladder: LadderModel,
})

const LaddersStore = types
  .model('LaddersStore', {
    results: types.map(LaddersMap),
    state: types.enumeration('State', ['pending', 'done', 'error']),
  })
  .actions(self => {
    const getLadder = (competitionId?: string) => {
      if (!competitionId) return
      const data = self.results.get(competitionId)
      if (data) {
        return data
      }
      if (self.state === 'pending') {
        return null
      }
      fetchLadder(competitionId)
      return
    }

    const fetchLadder = flow(function* fetchBalls(competitionId: string) {
      self.state = 'pending'
      const method = 'GET'
      const url = `${process.env.REACT_APP_API_URL}public/ladder/${competitionId}`
      // make request
      try {
        const response = yield RequestHandler({
          method,
          url,
          headers: { Authorization: generateAuthString(competitionId) },
        })
        if (response && response.ok) {
          const data = yield response.json()
          if (data) {
            self.results.put(
              LaddersMap.create({
                id: competitionId,
                ladder: data,
              })
            )
          }
          self.state = 'done'
        } else {
          self.state = 'done'
        }
      } catch (error) {
        console.warn('Error getting Ladder', error) // eslint-disable-line no-console
        self.state = 'error'
      }
    })

    return {
      getLadder,
      fetchLadder,
    }
  })

export default LaddersStore
