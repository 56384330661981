import { types } from 'mobx-state-tree'
import BaseMatchOfficialModel from './BaseMatchOfficialModel'
import BasePersonModel from '../../person/BasePersonModel'

const MatchOfficialModel = types.model(
  'OfficialModel',
  Object.assign({}, BasePersonModel, {
    matchOfficialTypeId: types.number,
    official: BaseMatchOfficialModel,
  })
)

export default MatchOfficialModel
