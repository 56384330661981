import { isNil } from 'lodash'
import { types } from 'mobx-state-tree'

const BaseMatchConditionsModel = types
  .model('ConditionsModel', {
    id: types.string,
    timestamp: types.string,
    boundaryTypeId: types.maybeNull(types.number),
    outfieldSpeedTypeId: types.maybeNull(types.number),
    pitchMoistureTypeId: types.maybeNull(types.number),
    pitchGrassTypeId: types.maybeNull(types.number),
    pitchQualityTypeId: types.maybeNull(types.number),
    temperatureTypeId: types.maybeNull(types.number),
    atmosTypeId: types.maybeNull(types.number),
    windTypeId: types.maybeNull(types.number),
    rainTypeId: types.maybeNull(types.number),
  })
  .preProcessSnapshot(sn => {
    if (!sn) return sn
    if (isNil(sn.timestamp)) sn.timestamp = new Date().toISOString()
    return sn
  })

export default BaseMatchConditionsModel
