import React from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { Heading, Flex, Text, Collapse, Button, useDisclosure } from '@chakra-ui/react'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import { each, find, findIndex, isNil, orderBy, round } from 'lodash'
import { convertBallsTotalToOvers, convertOversToBallsTotal } from '@ias-shared/cricket-logic'
import Theme from '../../theme/theme'

import { LadderProps } from '../../types/props'
import {
  IFixtureCompetitionMatchesModel,
  ILadderCompetitionLaddersColumnsModel,
  ILadderCompetitionLadderTeamModel,
} from '../../types/models'
import { filterFixtureData, oversToStrictDecimal } from '../../helpers/generalHelpers'
import { LadderMetricIds } from '../../data/reference'

export const Ladder: React.FC<LadderProps> = observer(({ ladder, columns, fixture, compTheme }) => {
  const { isOpen, onToggle } = useDisclosure()

  const columnData = orderBy(columns, ['order'], ['asc'])
  let ladderData = orderBy(toJS(ladder.ladderTeams), ['rank'], ['asc'])
  const fixtureData = filterFixtureData(fixture)

  const pId = 2
  const wId = 10
  const lId = 11
  const tId = 13
  const nrId = 14
  const formId = 62
  const rForId = 54
  const rAgstId = 55
  const ovForId = 58
  const ovAgstId = 59
  const forcId = 60
  const agstcId = 61
  const nrrId = 51
  const ptId = 50

  const modifyLadderDataVal = (
    team: number,
    ladderMetric: number,
    value: string | string[], // value -OR- [value, displayValue]
    isOvers?: boolean,
    isNrr?: boolean,
    isStringAppend?: boolean,
    isForAgst?: boolean
  ) => {
    let arrIdx = findIndex(ladderData[team].ladderTeamData, { ladderMetricId: ladderMetric })
    if (arrIdx === -1) {
      ladderData[team].ladderTeamData.push({
        ladderMetricId: ladderMetric,
        value: 0,
        displayValue: ladderMetric === formId ? '' : '0',
      })
      arrIdx = ladderData[team].ladderTeamData.length - 1
    }

    // *** value ***

    if (isOvers && typeof value === 'string') {
      // OVERS = convert to balls, then increment, and then convert back to overs
      const ballsFor: number = ladderData[team].ladderTeamData[arrIdx].value
      const ballsForIncr: number = convertOversToBallsTotal({ overs: value })
      ladderData[team].ladderTeamData[arrIdx].value = ballsFor + ballsForIncr
    } else if (isNrr || isForAgst) {
      // NRR/FOR/AGST = just set the value
      ladderData[team].ladderTeamData[arrIdx].value = typeof value === 'string' ? Number(value) : Number(value[0])
    } else if (!isStringAppend) {
      // ALL OTHER FIELDS = just increment
      ladderData[team].ladderTeamData[arrIdx].value += Number(value)
    }

    // *** displayValue ***

    if (isStringAppend) {
      // STRING APPEND FIELD = append to the displayValue
      ladderData[team].ladderTeamData[arrIdx].displayValue += value
    } else if (isOvers) {
      // OVERS = convert the value to overs for displayValue
      ladderData[team].ladderTeamData[arrIdx].displayValue = convertBallsTotalToOvers({
        balls: ladderData[team].ladderTeamData[arrIdx].value,
      }).toString()
    } else if (isForAgst && typeof value === 'object') {
      // FOR/AGST = just set the display value
      ladderData[team].ladderTeamData[arrIdx].displayValue = value[1]
    } else {
      // ALL OTHER FIELDS = update the displayValue
      ladderData[team].ladderTeamData[arrIdx].displayValue = ladderData[team].ladderTeamData[arrIdx].value.toString()
    }
  }

  // incorporate fixture inputs into ladders data
  let ladderModified = false
  each(fixtureData, (f: IFixtureCompetitionMatchesModel) => {
    const team0 = findIndex(ladderData, { teamId: f.matchTeams[0].teamId })
    const team1 = findIndex(ladderData, { teamId: f.matchTeams[1].teamId })
    if (team0 > -1 && team1 > -1) {
      if (!f.noResult) {
        let modified = false
        let team0runs: string | null = null
        let team0overs: string | null = null
        let team1runs: string | null = null
        let team1overs: string | null = null
        if (f.matchTeams[0].runs !== '' || f.matchTeams[1].dlsTarget !== '') {
          modified = true
          team0runs =
            f.dls && f.matchTeams[1].dlsTarget !== ''
              ? (Number(f.matchTeams[1].dlsTarget) - 1).toString()
              : f.matchTeams[0].runs
          // Runs For (Team 0)
          modifyLadderDataVal(team0, rForId, team0runs)
          // Runs Against (Team 1)
          modifyLadderDataVal(team1, rAgstId, team0runs)
        }
        if (f.matchTeams[1].runs !== '' || f.matchTeams[0].dlsTarget !== '') {
          modified = true
          team1runs =
            f.dls && f.matchTeams[0].dlsTarget !== ''
              ? (Number(f.matchTeams[0].dlsTarget) - 1).toString()
              : f.matchTeams[1].runs
          // Runs For (Team 1)
          modifyLadderDataVal(team1, rForId, team1runs)
          // Runs Against (Team 0)
          modifyLadderDataVal(team0, rAgstId, team1runs)
        }
        if (f.matchTeams[0].overs !== '' || f.matchTeams[1].dlsOvers !== '') {
          modified = true
          team0overs =
            f.dls && f.matchTeams[1].dlsOvers !== ''
              ? f.matchTeams[1].dlsOvers
              : Number(f.matchTeams[0].wickets) >= 10
              ? f.matchTeams[0].dlsOvers !== ''
                ? f.matchTeams[0].dlsOvers
                : f.matchTeams[0].maxOvers
              : f.matchTeams[0].overs
          // Overs For (Team 0)
          modifyLadderDataVal(team0, ovForId, team0overs, true)
          // Overs Against (Team 1)
          modifyLadderDataVal(team1, ovAgstId, team0overs, true)
        }
        if (f.matchTeams[1].overs !== '' || f.matchTeams[0].dlsOvers !== '') {
          modified = true
          team1overs =
            f.dls && f.matchTeams[0].dlsOvers !== ''
              ? f.matchTeams[0].dlsOvers
              : Number(f.matchTeams[1].wickets) >= 10
              ? f.matchTeams[1].dlsOvers !== ''
                ? f.matchTeams[1].dlsOvers
                : f.matchTeams[1].maxOvers
              : f.matchTeams[1].overs
          // Overs For (Team 1)
          modifyLadderDataVal(team1, ovForId, team1overs, true)
          // Overs Against (Team 0)
          modifyLadderDataVal(team0, ovAgstId, team1overs, true)
        }
        if (modified) {
          ladderModified = true
          ladderData[team0].hasModified = true
          ladderData[team1].hasModified = true
          modifyLadderDataVal(team0, pId, '1')
          modifyLadderDataVal(team1, pId, '1')

          if (!isNil(team0runs) && !isNil(team1runs) && !isNil(team0overs) && !isNil(team1overs)) {
            // check if we have a value-driven result for this fixture
            if (Number(team0runs) > Number(team1runs)) {
              // team 0 wins
              modifyLadderDataVal(team0, ptId, compTheme.config.winPt || '4')
              modifyLadderDataVal(team1, ptId, '0')
              modifyLadderDataVal(team0, wId, '1')
              modifyLadderDataVal(team1, wId, '0')
              modifyLadderDataVal(team1, lId, '1')
              modifyLadderDataVal(team0, lId, '0')
              modifyLadderDataVal(team0, tId, '0')
              modifyLadderDataVal(team1, tId, '0')
              modifyLadderDataVal(team0, nrId, '0')
              modifyLadderDataVal(team1, nrId, '0')
              modifyLadderDataVal(team0, formId, 'W', false, false, true)
              modifyLadderDataVal(team1, formId, 'L', false, false, true)
            } else if (Number(team1runs) > Number(team0runs)) {
              // team 1 wins
              modifyLadderDataVal(team1, ptId, compTheme.config.winPt || '4')
              modifyLadderDataVal(team0, ptId, '0')
              modifyLadderDataVal(team1, wId, '1')
              modifyLadderDataVal(team0, wId, '0')
              modifyLadderDataVal(team0, lId, '1')
              modifyLadderDataVal(team1, lId, '0')
              modifyLadderDataVal(team0, tId, '0')
              modifyLadderDataVal(team1, tId, '0')
              modifyLadderDataVal(team0, nrId, '0')
              modifyLadderDataVal(team1, nrId, '0')
              modifyLadderDataVal(team1, formId, 'W', false, false, true)
              modifyLadderDataVal(team0, formId, 'L', false, false, true)
            } else if (Number(team0runs) === Number(team1runs)) {
              // tied
              modifyLadderDataVal(team0, ptId, compTheme.config.tiePt || '2')
              modifyLadderDataVal(team1, ptId, compTheme.config.tiePt || '2')
              modifyLadderDataVal(team0, wId, '0')
              modifyLadderDataVal(team1, wId, '0')
              modifyLadderDataVal(team0, lId, '0')
              modifyLadderDataVal(team1, lId, '0')
              modifyLadderDataVal(team0, tId, '1')
              modifyLadderDataVal(team1, tId, '1')
              modifyLadderDataVal(team0, nrId, '0')
              modifyLadderDataVal(team1, nrId, '0')
              modifyLadderDataVal(team0, formId, 'T', false, false, true)
              modifyLadderDataVal(team1, formId, 'T', false, false, true)
            }
          }
        }
      } else {
        // no result / abandoned
        ladderModified = true
        ladderData[team0].hasModified = true
        ladderData[team1].hasModified = true
        modifyLadderDataVal(team0, pId, '1')
        modifyLadderDataVal(team1, pId, '1')
        modifyLadderDataVal(team0, ptId, compTheme.config.nrPt || '2')
        modifyLadderDataVal(team1, ptId, compTheme.config.nrPt || '2')
        modifyLadderDataVal(team0, nrId, '1')
        modifyLadderDataVal(team1, nrId, '1')
        modifyLadderDataVal(team0, wId, '0')
        modifyLadderDataVal(team1, wId, '0')
        modifyLadderDataVal(team0, lId, '0')
        modifyLadderDataVal(team1, lId, '0')
        modifyLadderDataVal(team0, tId, '0')
        modifyLadderDataVal(team1, tId, '0')
        modifyLadderDataVal(team0, formId, 'A', false, false, true)
        modifyLadderDataVal(team1, formId, 'A', false, false, true)
      }
    }
  })

  if (ladderModified) {
    // re-generate/re-calculate FOR, AGST and NRR values for each team in the ladder, and then re-sort the ladder
    ladderData = orderBy(
      ladderData.map((l: ILadderCompetitionLadderTeamModel, idx: number) => {
        if (l.ladderTeamData) {
          const ovForIdx = findIndex(l.ladderTeamData, { ladderMetricId: ovForId })
          const ovAgstIdx = findIndex(l.ladderTeamData, { ladderMetricId: ovAgstId })
          const rForIdx = findIndex(l.ladderTeamData, { ladderMetricId: rForId })
          const rAgstIdx = findIndex(l.ladderTeamData, { ladderMetricId: rAgstId })

          // NRR
          const oversForDec: number = oversToStrictDecimal(
            convertBallsTotalToOvers({
              balls:
                ovForIdx > -1 ? l.ladderTeamData[findIndex(l.ladderTeamData, { ladderMetricId: ovForId })].value : 0,
            })
          )
          const oversAgstDec: number = oversToStrictDecimal(
            convertBallsTotalToOvers({
              balls:
                ovAgstIdx > -1 ? l.ladderTeamData[findIndex(l.ladderTeamData, { ladderMetricId: ovAgstId })].value : 0,
            })
          )
          const runRateFor: number =
            rForIdx > -1
              ? l.ladderTeamData[findIndex(l.ladderTeamData, { ladderMetricId: rForId })].value / oversForDec
              : 0
          const runRateAgst: number =
            rAgstIdx > -1
              ? l.ladderTeamData[findIndex(l.ladderTeamData, { ladderMetricId: rAgstId })].value / oversAgstDec
              : 0
          modifyLadderDataVal(idx, nrrId, round(runRateFor - runRateAgst, 3).toString(), false, true)

          // FOR
          modifyLadderDataVal(
            idx,
            forcId,
            [
              round(runRateFor, 3).toString(),
              `${
                rForIdx > -1
                  ? l.ladderTeamData[findIndex(l.ladderTeamData, { ladderMetricId: rForId })].displayValue
                  : '0'
              }/${
                ovForIdx > -1
                  ? l.ladderTeamData[findIndex(l.ladderTeamData, { ladderMetricId: ovForId })].displayValue
                  : '0'
              }`,
            ],
            false,
            true,
            false,
            true
          )

          // AGST
          modifyLadderDataVal(
            idx,
            agstcId,
            [
              round(runRateAgst, 3).toString(),
              `${
                rAgstIdx > -1
                  ? l.ladderTeamData[findIndex(l.ladderTeamData, { ladderMetricId: rAgstId })].displayValue
                  : '0'
              }/${
                ovAgstIdx > -1
                  ? l.ladderTeamData[findIndex(l.ladderTeamData, { ladderMetricId: ovAgstId })].displayValue
                  : '0'
              }`,
            ],
            false,
            true,
            false,
            true
          )
        }
        return l
      }),
      [
        (l: ILadderCompetitionLadderTeamModel) => {
          return findIndex(l.ladderTeamData, { ladderMetricId: ptId }) > -1
            ? l.ladderTeamData[findIndex(l.ladderTeamData, { ladderMetricId: ptId })].value
            : 0
        },
        (l: ILadderCompetitionLadderTeamModel) => {
          return findIndex(l.ladderTeamData, { ladderMetricId: wId }) > -1
            ? l.ladderTeamData[findIndex(l.ladderTeamData, { ladderMetricId: wId })].value
            : 0
        },
        (l: ILadderCompetitionLadderTeamModel) => {
          return findIndex(l.ladderTeamData, { ladderMetricId: nrrId }) > -1
            ? l.ladderTeamData[findIndex(l.ladderTeamData, { ladderMetricId: nrrId })].value
            : 0
        },
        (l: ILadderCompetitionLadderTeamModel) => {
          return l.rank
        },
        (l: ILadderCompetitionLadderTeamModel) => {
          return findIndex(l.ladderTeamData, { ladderMetricId: pId }) > -1
            ? l.ladderTeamData[findIndex(l.ladderTeamData, { ladderMetricId: pId })].value
            : 0
        },
      ],
      ['desc', 'desc', 'desc', 'asc', 'desc']
    )

    // re-generate rank for each team in the ladder
    ladderData.map((l: ILadderCompetitionLadderTeamModel, idx: number) => {
      if (l.rank !== idx + 1 && l.rank !== 0) {
        l.hasModifiedRank = l.rank < idx + 1 ? 'down' : 'up'
      }
      l.rank = idx + 1
      return l
    })
  }

  return (
    <>
      <Flex direction="row" alignItems="center">
        <Button size="xs" mr="7px" onClick={onToggle}>
          {isOpen && <AddIcon />}
          {!isOpen && <MinusIcon />}
        </Button>
        <Heading as="h2" size="md" {...Theme.headingStyle}>
          {ladder.name !== '' && ladder.name !== ': ' ? ladder.name : 'Competition Table'}
        </Heading>
      </Flex>
      <Collapse style={{ width: '100%' }} in={!isOpen} animateOpacity>
        <Flex w="100%" direction="column" padding="7px 0 28px">
          {/* COLUMNS */}
          <Flex w="100%" h={['22px', '22px', '30px']} direction="row">
            <Flex {...Theme.columnStyle} flex={[0.75, 0.75, 0.5]}>
              <Text textAlign="center" {...Theme.cellInnerStyle}>
                #
              </Text>
            </Flex>
            <Flex {...Theme.columnStyle} flex={2.5}>
              <Text>Team</Text>
            </Flex>
            {columnData &&
              columnData.map((c: ILadderCompetitionLaddersColumnsModel) => (
                <Flex
                  key={`ladderColumn${ladder.competitionLadderId}_${ladder.id}_${c.ladderMetricId}`}
                  textAlign="center"
                  flex={1}
                  display={
                    c.ladderMetricId === forcId || c.ladderMetricId === agstcId
                      ? ['none', 'none', 'block', 'block']
                      : 'block'
                  }
                  {...Theme.columnStyle}
                >
                  <Text textAlign="center" {...Theme.cellInnerStyle}>
                    {c.title}
                  </Text>
                </Flex>
              ))}
          </Flex>
          {/* DATA */}
          {ladderData &&
            ladderData.map((d: ILadderCompetitionLadderTeamModel) => (
              <Flex
                key={`ladderData${ladder.competitionLadderId}_${ladder.id}_${d.competitionTeamId}`}
                w="100%"
                direction="row"
                backgroundColor={d.hasModified ? 'nrr.yellow.200' : 'nrr.white.main'}
                {...Theme.rowStyle}
              >
                <Flex {...Theme.cellStyle} flex={[0.75, 0.75, 0.5]}>
                  <Text
                    textAlign="center"
                    fontWeight={d.hasModifiedRank !== '' ? 'bold' : 'normal'}
                    textColor={
                      d.hasModifiedRank !== ''
                        ? d.hasModifiedRank === 'up'
                          ? 'nrr.positiveGreen'
                          : 'nrr.negativeRed'
                        : 'black'
                    }
                    {...Theme.cellInnerStyle}
                  >
                    {d.hasModifiedRank !== ''
                      ? `${d.rank}${d.hasModifiedRank === 'up' ? ' ↑' : ' ↓'}`
                      : d.rank === 0
                      ? '-'
                      : d.rank}
                  </Text>
                </Flex>
                <Flex {...Theme.cellStyle} flex={2.5}>
                  <Text>{d.teamName}</Text>
                </Flex>
                {columnData &&
                  columnData.map((c: ILadderCompetitionLaddersColumnsModel) => (
                    <Flex
                      key={`ladderData${ladder.competitionLadderId}_${ladder.id}_${d.competitionTeamId}_${c.ladderMetricId}`} // eslint-disable-line max-len
                      textAlign="center"
                      flex={1}
                      display={
                        c.ladderMetricId === forcId || c.ladderMetricId === agstcId
                          ? ['none', 'none', 'block', 'block']
                          : 'block'
                      }
                      {...Theme.cellStyle}
                    >
                      <Text {...Theme.cellInnerStyle}>
                        {find(LadderMetricIds, { id: c.ladderMetricId })?.abbrev === 'NRR'
                          ? !isNil(find(d.ladderTeamData, { ladderMetricId: c.ladderMetricId })?.value)
                            ? round(find(d.ladderTeamData, { ladderMetricId: c.ladderMetricId })?.value || 0, 3)
                            : ''
                          : `${find(d.ladderTeamData, { ladderMetricId: c.ladderMetricId })?.displayValue || ''}${
                              find(LadderMetricIds, { id: c.ladderMetricId })?.abbrev === 'M' && d.hasModified
                                ? '*'
                                : ''
                            }`}
                      </Text>
                    </Flex>
                  ))}
              </Flex>
            ))}
        </Flex>
      </Collapse>
    </>
  )
})
