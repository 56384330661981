import { types } from 'mobx-state-tree'

const VenueFieldDimensionsModel = types.model('VenueFieldDimensionsModel', {
  id: types.identifier,
  d1_end1: types.maybeNull(types.number),
  d2: types.maybeNull(types.number),
  d3_end2: types.maybeNull(types.number),
  d4: types.maybeNull(types.number),
})

export default VenueFieldDimensionsModel
