import React from 'react'
import { Tabs, TabList, TabPanels, Tab, TabPanel, Spinner, Flex, Text } from '@chakra-ui/react'
import { WarningTwoIcon } from '@chakra-ui/icons'
import {
  IFixtureCompetitionStageModel,
  IFixturesStore,
  ILadderCompetitionStageModel,
  ILaddersStore,
  IFixtureCompetitionMatchesModel,
} from '../types/models'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { filter, find, orderBy } from 'lodash'
import Theme from '../theme/theme'
import { useMst } from '../data/stores/rootStore'
import { CompetitionStage } from '../components/CompetitionStage/CompetitionStage'
import { Footer } from '../components/Footer/Footer'
import { ThemeProps } from '../types/props'

export const Calc: React.FC = observer(() => {
  const { id } = useParams<{ id: string }>()
  const compTheme = Theme.competitions.find((c: ThemeProps) => {
    return c.id === id && c.enviro === process.env.REACT_APP_ENV
  })
  if (!compTheme) {
    return (
      <Flex w="100%" h="calc(100vh - 130px)" alignItems="center" justifyContent="center" padding="14px">
        <Flex direction="row" alignItems="center">
          <WarningTwoIcon w={8} h={8} color="red.500" />
          <Text marginLeft="14px" fontWeight="bold">
            Invalid competition ID
          </Text>
        </Flex>
      </Flex>
    )
  }

  const {
    fixtures,
    ladders,
  }: {
    fixtures: IFixturesStore
    ladders: ILaddersStore
  } = useMst()

  fixtures.getFixture(id)
  ladders.getLadder(id)
  const fixtureData = fixtures.results.get(id || '')?.fixture
  const ladderData = ladders.results.get(id || '')?.ladder

  if (!fixtureData || !ladderData)
    return (
      <Flex w="100%" h="calc(100vh - 130px)" alignItems="center" justifyContent="center" padding="14px">
        <Flex direction="row">
          <Spinner color={compTheme.primaryDarkColor} />
          <Text marginLeft="14px" fontWeight="bold">
            Fetching data...
          </Text>
        </Flex>
      </Flex>
    )

  const competitionStagesLadders: ILadderCompetitionStageModel[] = filter(
    orderBy(ladderData.competitionStages, ['order'], ['asc']),
    (s: ILadderCompetitionStageModel) => {
      return (s.competitionLadders && s.competitionLadders.length > 0) ||
        (s.competitionPools && s.competitionPools.length > 0 && s.competitionPools[0].ladders.length > 0)
        ? true
        : false
    }
  )
  const competitionStagesFixtures: IFixtureCompetitionStageModel[] = orderBy(
    fixtureData.competitionStages,
    ['order'],
    ['asc']
  )

  let mostRecentStage: string | null = null
  competitionStagesFixtures.forEach((stage: IFixtureCompetitionStageModel) => {
    stage.matches?.forEach((match: IFixtureCompetitionMatchesModel) => {
      if ((match.matchStatusId || 0) > 0) {
        // find newest stage with an in progress or completed match
        mostRecentStage = stage.id
      }
    })
  })
  let mostRecentStageIndex: number = competitionStagesLadders.findIndex((s: ILadderCompetitionStageModel) => {
    return mostRecentStage && mostRecentStage === s.id
  })
  if (mostRecentStageIndex === -1) mostRecentStageIndex = 0

  return (
    <Flex w="100%" direction="column">
      <Flex
        w="100%"
        backgroundColor="#FFFFFF"
        alignItems="center"
        justifyContent="center"
        padding={['7px', '7px', '14px']}
      >
        {competitionStagesLadders.length === 1 && (
          <CompetitionStage
            ladder={find(competitionStagesLadders, { id: competitionStagesLadders[0].id })}
            fixture={find(competitionStagesFixtures, { id: competitionStagesLadders[0].id })}
            compTheme={compTheme}
          />
        )}
        {competitionStagesLadders.length > 1 && (
          <Tabs w="100%" size="lg" align="center" variant="enclosed" defaultIndex={mostRecentStageIndex}>
            <TabList>
              {competitionStagesLadders.map((s: ILadderCompetitionStageModel) => (
                <Tab key={`tab${s.id}`}>{s.name}</Tab>
              ))}
            </TabList>
            <TabPanels>
              {competitionStagesLadders.map((t: ILadderCompetitionStageModel) => (
                <TabPanel key={`tabPanel${t.id}`} padding={[2, 2, 4]}>
                  <CompetitionStage
                    ladder={find(competitionStagesLadders, { id: t.id })}
                    fixture={find(competitionStagesFixtures, { id: t.id })}
                    compTheme={compTheme}
                  />
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        )}
      </Flex>
      <Footer />
    </Flex>
  )
})
