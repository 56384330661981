import { types } from 'mobx-state-tree'
import BaseMatchConditionsModel from '../MatchConditions/BaseMatchConditionsModel'

export const MatchDatesModel = types.model('MatchDatesModel', {
  id: types.string,
  matchDayNum: types.number,
  conditions: types.array(BaseMatchConditionsModel),
  startDateTime: types.maybeNull(types.string),
  endDateTime: types.maybeNull(types.string),
})

export default MatchDatesModel
