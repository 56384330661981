import { LadderMetricsEnum } from '../../types/props'

export const LadderMetricIds: LadderMetricsEnum[] = [
  {
    id: 1,
    abbrev: 'RANK',
    name: 'RANK',
  },
  {
    id: 2,
    abbrev: 'M',
    name: 'MATCHES_PLAYED',
  },
  {
    id: 10,
    abbrev: 'W',
    name: 'WON_TOTAL',
  },
  {
    id: 11,
    abbrev: 'L',
    name: 'LOST_TOTAL',
  },
  {
    id: 12,
    abbrev: 'D',
    name: 'DRAW_TOTAL',
  },
  {
    id: 13,
    abbrev: 'T',
    name: 'TIE_TOTAL',
  },
  {
    id: 14,
    abbrev: 'N/R',
    name: 'ABANDONED_TOTAL',
  },
  {
    id: 50,
    abbrev: 'PT',
    name: 'POINTS',
  },
  {
    id: 51,
    abbrev: 'NRR',
    name: 'NETRR',
  },
  {
    id: 54,
    abbrev: 'RFOR',
    name: 'FOR_RUNS',
  },
  {
    id: 55,
    abbrev: 'RAGST',
    name: 'AGAINST_RUNS',
  },
  {
    id: 56,
    abbrev: 'WFOR',
    name: 'FOR_WKTS',
  },
  {
    id: 57,
    abbrev: 'WAGST',
    name: 'AGAINST_WKTS',
  },
  {
    id: 58,
    abbrev: 'OVFOR',
    name: 'FOR_OVERS',
  },
  {
    id: 59,
    abbrev: 'OVAGST',
    name: 'AGAINST_OVERS',
  },
  {
    id: 60,
    abbrev: 'FOR',
    name: 'FOR_COMBINED',
  },
  {
    id: 61,
    abbrev: 'AGST',
    name: 'AGAINST_COMBINED',
  },
  {
    id: 62,
    abbrev: 'FORM',
    name: 'FORM',
  },
]
