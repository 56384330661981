import React from 'react'
import { observer } from 'mobx-react-lite'
import Moment from 'moment-timezone'
import { Flex, Heading, Text, Box, Input, Checkbox, Alert, AlertDescription, AlertIcon, Button } from '@chakra-ui/react'
import Theme from '../../theme/theme'

import { FixtureColumnsProps, FixtureProps } from '../../types/props'
import { IFixtureCompetitionMatchesModel } from '../../types/models'
import { filterFixtureData } from '../../helpers/generalHelpers'

export const Fixture: React.FC<FixtureProps> = observer(({ fixture }) => {
  const fixtureColumns: FixtureColumnsProps[] = [
    { id: 'match', name: 'Match Info', flex: 0.75 },
    { id: 'teams', name: 'Teams', flex: 1.75 },
    { id: 'batFirst', name: 'Bat First?', flex: 0.75 },
    { id: 'maxOvers', name: 'Max Overs', flex: 0.75 },
    { id: 'overs', name: 'Overs', flex: 0.75 },
    { id: 'runs', name: 'Runs', flex: 0.75 },
    { id: 'wickets', name: 'Wkts', flex: 0.75 },
    { id: 'dls', name: 'DLS?', flex: 0.5 },
    { id: 'dlsOvers', name: 'DLS Overs', flex: 0.75 },
    { id: 'dlsTarget', name: 'DLS Target', flex: 0.75 },
  ]
  const fixtureData = filterFixtureData(fixture)
  const tabbableElements = 16

  const changeBatFirst = ({ match, teamNum }: { match: IFixtureCompetitionMatchesModel; teamNum: number }) => {
    match.matchTeams[teamNum].setBatFirst()
    if (
      match.matchTeams[1 - teamNum].batFirst === match.matchTeams[teamNum].batFirst &&
      match.matchTeams[1 - teamNum].batFirst
    ) {
      match.matchTeams[1 - teamNum].setBatFirst()
    }
  }

  const changeNoResult = ({ match }: { match: IFixtureCompetitionMatchesModel }) => {
    match.setNoResult(!match.noResult)
  }

  const handleClearValues = (match: IFixtureCompetitionMatchesModel) => {
    match.setDls(false)
    match.setNoResult(false)
    match.matchTeams[0].setMaxOvers(match.matchConfigs.maxOvers?.toString() || '20')
    match.matchTeams[1].setMaxOvers(match.matchConfigs.maxOvers?.toString() || '20')
    match.matchTeams[0].setBatFirst(false)
    match.matchTeams[1].setBatFirst(false)
    match.matchTeams[0].setOvers('')
    match.matchTeams[1].setOvers('')
    match.matchTeams[0].setRuns('')
    match.matchTeams[1].setRuns('')
    match.matchTeams[0].setWickets('')
    match.matchTeams[1].setWickets('')
    match.matchTeams[0].setDlsOvers('')
    match.matchTeams[1].setDlsOvers('')
    match.matchTeams[0].setDlsTarget('')
    match.matchTeams[1].setDlsTarget('')
  }

  return (
    <Flex w="100%" direction="column" alignItems="center" marginTop="14px">
      <Heading as="h2" size="md" {...Theme.headingStyle}>
        Matches
      </Heading>
      {fixtureData && fixtureData.length > 0 && (
        <Box w={['calc(100vw - 30px)', 'calc(100vw - 70px)', '100%']} overflowX="scroll">
          <Flex direction="column" padding="7px 0 28px" width={['640px', '682px', '100%']}>
            {/* COLUMNS */}
            <Flex w="100%" h={['33px', '33px', '46px']} direction="row" justifyContent="center" alignItems="center">
              {fixtureColumns.map((col: FixtureColumnsProps, idx: number) => (
                <Flex key={`fixtureColumnData${idx}`} {...Theme.columnStyle} flex={col.flex}>
                  <Text w="100%" textAlign="center" lineHeight={['16px', '16px', 'unset', 'unset']}>
                    {col.name}
                  </Text>
                </Flex>
              ))}
            </Flex>
            {/* DATA */}
            {fixtureData.map((f: IFixtureCompetitionMatchesModel, idx: number) => (
              <Flex key={`fixtureData${f.id}`} w="100%" direction="row" {...Theme.rowStyle}>
                {fixtureColumns &&
                  fixtureColumns.map((col: FixtureColumnsProps) => (
                    <Flex key={`fixtureData${f.id}_${col.id}`} flex={col.flex} {...Theme.cellStyle}>
                      {col.id === 'match' && (
                        <Flex
                          direction="column"
                          alignItems="center"
                          justifyContent="space-between"
                          {...Theme.cellInnerStyle}
                        >
                          <Text>{Moment(f.matchDates[0].startDateTime)?.format('DD/MM/YY')}</Text>
                          <Checkbox
                            {...Theme.formElementCoreStyleSmall}
                            size="sm"
                            isChecked={f.noResult ? true : false}
                            onChange={() => changeNoResult({ match: f })}
                            tabIndex={1 + idx * tabbableElements}
                          >
                            N/R
                          </Checkbox>
                          <Button
                            marginBottom="10px"
                            colorScheme="red"
                            size="xs"
                            onClick={() => handleClearValues(f)}
                            tabIndex={2 + idx * tabbableElements}
                          >
                            Clear
                          </Button>
                        </Flex>
                      )}
                      {col.id === 'teams' && (
                        <Box {...Theme.cellInnerStyle}>
                          <Text
                            paddingTop={['10px', '10px', '7px']}
                            textAlign="center"
                            textOverflow="ellipsis"
                            {...Theme.formElementCoreStyle}
                          >
                            {f.matchTeams[0].name}
                          </Text>
                          <Text
                            paddingTop={['10px', '10px', '7px']}
                            textAlign="center"
                            textOverflow="ellipsis"
                            {...Theme.formElementCoreStyle}
                          >
                            {f.matchTeams[1].name}
                          </Text>
                        </Box>
                      )}
                      {col.id === 'batFirst' && (
                        <Flex direction="column" alignItems="center" {...Theme.cellInnerStyle}>
                          <Checkbox
                            {...Theme.formElementCoreStyle}
                            size="lg"
                            isDisabled={f.noResult ? true : false}
                            isChecked={f.matchTeams[0].batFirst}
                            onChange={() => changeBatFirst({ match: f, teamNum: 0 })}
                            tabIndex={3 + idx * tabbableElements}
                          />
                          <Checkbox
                            {...Theme.formElementCoreStyle}
                            size="lg"
                            isDisabled={f.noResult ? true : false}
                            isChecked={f.matchTeams[1].batFirst}
                            onChange={() => changeBatFirst({ match: f, teamNum: 1 })}
                            tabIndex={11 + idx * tabbableElements}
                          />
                        </Flex>
                      )}
                      {col.id === 'maxOvers' && (
                        <Box {...Theme.cellInnerStyle}>
                          <Input
                            {...Theme.formElementCoreStyle}
                            isDisabled={
                              f.noResult ||
                              (!f.matchTeams[0].batFirst && !f.matchTeams[1].batFirst) ||
                              f.matchTeams[1].batFirst ||
                              f.matchTeams[0].overs !== '' ||
                              f.matchTeams[1].overs !== ''
                            }
                            onChange={(event: any) => {
                              f.matchTeams[0].setMaxOvers(event.target.value)
                              f.matchTeams[1].setMaxOvers(event.target.value)
                            }}
                            value={
                              f.matchTeams[0].batFirst
                                ? f.matchTeams[0].maxOvers
                                : f.matchTeams[0].dlsOvers !== ''
                                ? f.matchTeams[0].dlsOvers
                                : f.matchTeams[1].maxOvers
                            }
                            tabIndex={4 + idx * tabbableElements}
                          />
                          <Input
                            {...Theme.formElementCoreStyle}
                            isDisabled={
                              f.noResult ||
                              (!f.matchTeams[0].batFirst && !f.matchTeams[1].batFirst) ||
                              f.matchTeams[0].batFirst ||
                              f.matchTeams[0].overs !== '' ||
                              f.matchTeams[1].overs !== ''
                            }
                            onChange={(event: any) => {
                              f.matchTeams[1].setMaxOvers(event.target.value)
                              f.matchTeams[0].setMaxOvers(event.target.value)
                            }}
                            value={
                              f.matchTeams[1].batFirst
                                ? f.matchTeams[1].maxOvers
                                : f.matchTeams[1].dlsOvers !== ''
                                ? f.matchTeams[1].dlsOvers
                                : f.matchTeams[0].maxOvers
                            }
                            tabIndex={12 + idx * tabbableElements}
                          />
                        </Box>
                      )}
                      {col.id === 'overs' && (
                        <Box {...Theme.cellInnerStyle}>
                          <Input
                            {...Theme.formElementCoreStyle}
                            onChange={(event: any) => f.matchTeams[0].setOvers(event.target.value)}
                            isDisabled={f.noResult || (!f.matchTeams[0].batFirst && !f.matchTeams[1].batFirst)}
                            value={f.matchTeams[0].overs}
                            tabIndex={5 + idx * tabbableElements}
                          />
                          <Input
                            {...Theme.formElementCoreStyle}
                            onChange={(event: any) => f.matchTeams[1].setOvers(event.target.value)}
                            isDisabled={f.noResult || (!f.matchTeams[0].batFirst && !f.matchTeams[1].batFirst)}
                            value={f.matchTeams[1].overs}
                            tabIndex={13 + idx * tabbableElements}
                          />
                        </Box>
                      )}
                      {col.id === 'runs' && (
                        <Box {...Theme.cellInnerStyle}>
                          <Input
                            {...Theme.formElementCoreStyle}
                            onChange={(event: any) => f.matchTeams[0].setRuns(event.target.value)}
                            isDisabled={f.noResult || (!f.matchTeams[0].batFirst && !f.matchTeams[1].batFirst)}
                            value={f.matchTeams[0].runs}
                            tabIndex={6 + idx * tabbableElements}
                          />
                          <Input
                            {...Theme.formElementCoreStyle}
                            onChange={(event: any) => f.matchTeams[1].setRuns(event.target.value)}
                            isDisabled={f.noResult || (!f.matchTeams[0].batFirst && !f.matchTeams[1].batFirst)}
                            value={f.matchTeams[1].runs}
                            tabIndex={14 + idx * tabbableElements}
                          />
                        </Box>
                      )}
                      {col.id === 'wickets' && (
                        <Box {...Theme.cellInnerStyle}>
                          <Input
                            {...Theme.formElementCoreStyle}
                            onChange={(event: any) => f.matchTeams[0].setWickets(event.target.value)}
                            isDisabled={f.noResult || (!f.matchTeams[0].batFirst && !f.matchTeams[1].batFirst)}
                            value={f.matchTeams[0].wickets}
                            tabIndex={7 + idx * tabbableElements}
                          />
                          <Input
                            {...Theme.formElementCoreStyle}
                            onChange={(event: any) => f.matchTeams[1].setWickets(event.target.value)}
                            isDisabled={f.noResult || (!f.matchTeams[0].batFirst && !f.matchTeams[1].batFirst)}
                            value={f.matchTeams[1].wickets}
                            tabIndex={15 + idx * tabbableElements}
                          />
                        </Box>
                      )}
                      {col.id === 'dls' && (
                        <Flex alignItems="center" justifyContent="center" {...Theme.cellInnerStyle}>
                          <Checkbox
                            size="lg"
                            isChecked={f.dls ? true : false}
                            isDisabled={f.noResult || (!f.matchTeams[0].batFirst && !f.matchTeams[1].batFirst)}
                            onChange={() => f.setDls(f.dls ? false : true)}
                            tabIndex={8 + idx * tabbableElements}
                          />
                        </Flex>
                      )}
                      {col.id === 'dlsOvers' && (
                        <Box {...Theme.cellInnerStyle}>
                          <Input
                            {...Theme.formElementCoreStyle}
                            onChange={(event: any) => f.matchTeams[0].setDlsOvers(event.target.value)}
                            isDisabled={
                              f.noResult ||
                              !f.dls ||
                              (f.dls &&
                                (f.matchTeams[0].batFirst || (!f.matchTeams[0].batFirst && !f.matchTeams[1].batFirst)))
                            }
                            value={f.matchTeams[0].dlsOvers}
                            tabIndex={9 + idx * tabbableElements}
                          />
                          <Input
                            {...Theme.formElementCoreStyle}
                            onChange={(event: any) => f.matchTeams[1].setDlsOvers(event.target.value)}
                            isDisabled={
                              f.noResult ||
                              !f.dls ||
                              (f.dls &&
                                (f.matchTeams[1].batFirst || (!f.matchTeams[0].batFirst && !f.matchTeams[1].batFirst)))
                            }
                            value={f.matchTeams[1].dlsOvers}
                            tabIndex={16 + idx * tabbableElements}
                          />
                        </Box>
                      )}
                      {col.id === 'dlsTarget' && (
                        <Box {...Theme.cellInnerStyle}>
                          <Input
                            {...Theme.formElementCoreStyle}
                            onChange={(event: any) => f.matchTeams[0].setDlsTarget(event.target.value)}
                            isDisabled={
                              f.noResult ||
                              !f.dls ||
                              (f.dls &&
                                (f.matchTeams[0].batFirst || (!f.matchTeams[0].batFirst && !f.matchTeams[1].batFirst)))
                            }
                            value={f.matchTeams[0].dlsTarget}
                            tabIndex={10 + idx * tabbableElements}
                          />
                          <Input
                            {...Theme.formElementCoreStyle}
                            onChange={(event: any) => f.matchTeams[1].setDlsTarget(event.target.value)}
                            isDisabled={
                              f.noResult ||
                              !f.dls ||
                              (f.dls &&
                                (f.matchTeams[1].batFirst || (!f.matchTeams[0].batFirst && !f.matchTeams[1].batFirst)))
                            }
                            value={f.matchTeams[1].dlsTarget}
                            tabIndex={17 + idx * tabbableElements}
                          />
                        </Box>
                      )}
                    </Flex>
                  ))}
              </Flex>
            ))}
          </Flex>
        </Box>
      )}
      {(!fixtureData || fixtureData.length === 0) && (
        <Box w={['100%', '100%', '100%', '60%']} margin="14px 0 21px">
          <Alert status="warning" justifyContent="center">
            <AlertIcon />
            <AlertDescription fontSize={['sm', 'md']} lineHeight={['sm', 'sm', 'md']}>
              No upcoming or in progress matches
            </AlertDescription>
          </Alert>
        </Box>
      )}
    </Flex>
  )
})
