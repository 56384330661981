import { ChakraProvider } from '@chakra-ui/react'
import ReactDOM from 'react-dom'
import { Provider, appContext } from './data/stores/rootStore'
import { Global } from '@emotion/react'
import { datadogRum } from '@datadog/browser-rum'

import Theme from './theme/theme'
import { App } from './App'

if (process.env.REACT_APP_ENV !== 'DEV' && process.env.REACT_APP_DATADOG_ENABLED === 'true') {
  // DataDog
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APP_ID || '',
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
    site: 'datadoghq.com',
    service: process.env.REACT_APP_DATADOG_SERVICE || 'nrrc',
    env: process.env.REACT_APP_ENV,
    version: process.env.REACT_APP_VERSION,
    sampleRate: 100,
    replaySampleRate: 100,
    trackInteractions: true,
    allowedTracingOrigins: [new RegExp(`${process.env.REACT_APP_API_URL}*`)],
    defaultPrivacyLevel: 'allow',
  })
  datadogRum.startSessionReplayRecording()
}

ReactDOM.render(
  <ChakraProvider theme={Theme}>
    <Global
      styles={{
        body: {
          backgroundColor: Theme.colors.nrr.backgroundGray,
        },
      }}
    />
    <Provider value={appContext}>
      <App />
    </Provider>
  </ChakraProvider>,
  document.getElementById('root')
)
