import { filter, isNil } from 'lodash'
import md5 from 'md5'
import { IFixtureCompetitionMatchesModel } from '../types/models'

export const filterFixtureData = (fixture: IFixtureCompetitionMatchesModel[]) => {
  return filter(fixture, (f: IFixtureCompetitionMatchesModel) => {
    return (
      f.matchTeams.length === 2 &&
      f.matchTeams[0].name !== 'Bye' &&
      f.matchTeams[1].name !== 'Bye' &&
      (isNil(f.matchStatusId) || f.matchStatusId < 3)
    )
  })
}

export const oversToStrictDecimal = (value: number) => {
  // translate "over.ball" to a strict decimal value
  return Math.floor(value) + (value - Math.floor(value)) / 0.6
}

export const generateAuthString = (competitionId: string) => {
  // generate authorization key for API calls
  const secret = process.env.REACT_APP_AUTH_KEY

  const utcDate = new Date(new Date().toUTCString())
  const timestamp = new Date(utcDate.valueOf() + 60 * 60 * 1000)
  const hash = md5(`${competitionId}-${timestamp.valueOf()}-${secret}`)

  return `${timestamp.valueOf()}-${hash}`
}
