import React from 'react'
import { observer } from 'mobx-react-lite'
import { Flex, Box, Alert, AlertIcon, AlertDescription } from '@chakra-ui/react'
import { orderBy } from 'lodash'

import { CompetitionStageProps } from '../../types/props'
import {
  IFixtureCompetitionMatchesModel,
  ILadderCompetitionLadderModel,
  ILadderCompetitionLaddersModel,
} from '../../types/models'
import { Ladder } from '../Ladder/Ladder'
import { Fixture } from '../Fixture/Fixture'

export const CompetitionStage: React.FC<CompetitionStageProps> = observer(({ ladder, fixture, compTheme }) => {
  if (!ladder || !fixture) return null
  const competitionLaddersData = orderBy(ladder.competitionLadders, ['order'], ['asc'])
  const competitionFixturesData = orderBy(fixture.matches, [
    (f: IFixtureCompetitionMatchesModel) => {
      return f.matchDates ? orderBy(f.matchDates, ['startDateTime'], ['asc'])[0].startDateTime : null
    },
  ])
  const hasLadders = competitionLaddersData && competitionLaddersData.length > 0

  return (
    <Flex w="100%" minH="500px" direction="column" alignItems="center">
      {/* LADDERS */}
      {hasLadders &&
        competitionLaddersData.map((cl: ILadderCompetitionLaddersModel) => (
          <React.Fragment key={`ladders${cl.id}`}>
            {orderBy(cl.ladders, ['order', 'name'], ['asc', 'asc'])?.map((l: ILadderCompetitionLadderModel) => (
              <Ladder
                key={`ladder${l.id}_${l.competitionLadderId}`}
                ladder={l}
                columns={cl.ladderColumns}
                fixture={competitionFixturesData}
                compTheme={compTheme}
              />
            ))}
          </React.Fragment>
        ))}
      {hasLadders && (
        <Box w={['100%', '100%', '100%', '60%']} margin="14px 0 21px">
          <Alert status="info" justifyContent="center">
            <AlertIcon />
            <AlertDescription fontSize={['sm', 'md']} lineHeight={['sm', 'sm', 'md']}>
              Ladders/tables are updated shortly after the conclusion of each match.
            </AlertDescription>
          </Alert>
        </Box>
      )}
      {/* FIXTURES */}
      {hasLadders && competitionFixturesData && competitionFixturesData.length > 0 && (
        <Fixture fixture={competitionFixturesData} />
      )}
      {/* ALERT */}
      {!hasLadders && (
        <Box w="100%" margin="14px">
          <Alert status="warning">
            <AlertIcon />
            <AlertDescription fontSize={['sm', 'md']} lineHeight={['sm', 'sm', 'md']}>
              No ladder/table for this competition stage.
            </AlertDescription>
          </Alert>
        </Box>
      )}
    </Flex>
  )
})
