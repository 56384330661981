import { isNil } from 'lodash'
import { types } from 'mobx-state-tree'
import MatchPlayerModel from '../../person/MatchPlayerModel'
import MatchTeamCore from './MatchTeamCore'

export const MatchTeamModel = types
  .model(
    'MatchTeamModel',
    Object.assign({}, MatchTeamCore, {
      matchPlayers: types.array(MatchPlayerModel),
      isHome: false,
      // NRR calc specific properties below
      batFirst: false,
      maxOvers: types.string,
      overs: '',
      runs: '',
      wickets: '',
      dlsOvers: '',
      dlsTarget: '',
    })
  )
  .actions(self => {
    const setBatFirst = (value?: boolean) => {
      if (!isNil(value)) {
        self.batFirst = value
      } else {
        self.batFirst = !self.batFirst
      }
    }
    const setMaxOvers = (value: string) => {
      if (value === '' || !isNaN(Number(value))) {
        // ensure value is numeric, or empty. anything else is not allowed
        self.maxOvers = value
      }
    }
    const setOvers = (value: string) => {
      if (value === '' || !isNaN(Number(value))) {
        // ensure value is numeric, or empty. anything else is not allowed
        self.overs = value
      }
    }
    const setRuns = (value: string) => {
      if (value === '' || !isNaN(Number(value))) {
        // ensure value is numeric, or empty. anything else is not allowed
        self.runs = value
      }
    }
    const setWickets = (value: string) => {
      if (value === '' || !isNaN(Number(value))) {
        // ensure value is numeric, or empty. anything else is not allowed
        self.wickets = value
      }
    }
    const setDlsOvers = (value: string) => {
      if (value === '' || !isNaN(Number(value))) {
        // ensure value is numeric, or empty. anything else is not allowed
        self.dlsOvers = value
      }
    }
    const setDlsTarget = (value: string) => {
      if (value === '' || !isNaN(Number(value))) {
        // ensure value is numeric, or empty. anything else is not allowed
        self.dlsTarget = value
      }
    }
    return {
      setBatFirst,
      setMaxOvers,
      setOvers,
      setRuns,
      setWickets,
      setDlsOvers,
      setDlsTarget,
    }
  })

export default MatchTeamModel
