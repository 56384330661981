import { types } from 'mobx-state-tree'

const PersonModel = types.model('PersonModel', {
  id: types.string,
  firstName: types.maybeNull(types.string),
  middleName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  fullName: types.maybeNull(types.string),
  dob: types.maybeNull(types.string),
  notes: types.maybeNull(types.string),
  gender: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  alphaName: types.maybeNull(types.string),
  cardNameF: types.maybeNull(types.string),
  cardNameS: types.maybeNull(types.string),
  nationalityCountryCode: types.maybeNull(types.string),
})

export default PersonModel
