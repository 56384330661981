import { types } from 'mobx-state-tree'
import PersonModel from './PersonModel'

const PlayerModel = types.model('PlayerModel', {
  id: types.string,
  person: types.maybeNull(PersonModel),
  battingHandedId: types.maybeNull(types.number),
  bowlingHandedId: types.maybeNull(types.number),
  primaryThrowingArmId: types.maybeNull(types.number),
  bowlingTypeId: types.maybeNull(types.number),
  selected: false,
})

export default PlayerModel
