import { types } from 'mobx-state-tree'
import BasePersonModel from './BasePersonModel'
import PlayerModel from './PlayerModel'

const MatchPlayerModel = types.model(
  'MatchPlayerModel',
  Object.assign({}, BasePersonModel, {
    shirtNumber: types.optional(types.maybeNull(types.number), 0),
    selectionNumber: types.maybeNull(types.number),
    playerFlags: types.maybeNull(types.number),
    wicketKeeper: types.boolean,
    substitute: false,
    captain: types.boolean,
    viceCaptain: types.maybeNull(types.boolean),
    player: PlayerModel,
    isPlayerOfMatch: false,
  })
)

export default MatchPlayerModel
