import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import FooterLogo from '../../assets/logo-ias.png'

export const Footer: React.FC = () => {
  return (
    <Flex flex={1} direction="column" alignItems="center" padding="28px 0 14px">
      <Text fontSize="xs">Powered by:</Text>
      <img src={FooterLogo} alt="Powered by InteractSport - Part of SportRadar" width="228px" height="39px" />
      <Text marginTop="14px" fontSize="xs" color="nrr.textGray">
        v{process.env.REACT_APP_VERSION}
      </Text>
    </Flex>
  )
}
