import React from 'react'
import { Flex, Heading } from '@chakra-ui/react'
import { useParams, useHistory } from 'react-router-dom'
import Theme from '../../theme/theme'
import { ThemeProps } from '../../types/props'

export const Header: React.FC = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const compTheme = Theme.competitions.find((c: ThemeProps) => {
    return c.id === id && c.enviro === process.env.REACT_APP_ENV
  })

  return (
    <Flex
      w="100%"
      minH="100px"
      flex={1}
      direction={['column', 'row']}
      backgroundColor={compTheme?.primaryColor || Theme.colors.nrr.blue.main}
      borderBottom={`solid 3px ${compTheme?.primaryDarkColor || Theme.colors.nrr.blue[600]}`}
      shadow={Theme.shadows.nrr.light}
      alignItems="center"
      justifyContent="center"
    >
      <img
        src={id && compTheme ? `/${id}/logo-comp.png` : '/logo-comp.png'}
        alt={compTheme?.name || 'Net Run Rate Calculator'}
        width="253px"
        height="74px"
      />
      <Heading
        as="h1"
        size="lg"
        mb={['14px', '0px']}
        ml={['0px', '28px']}
        color={compTheme?.headingTextColor || '#FFFFFF'}
        onClick={() => {
          if (!compTheme) history.push('/')
        }}
        cursor={!compTheme ? 'pointer' : 'unset'}
      >
        Net Run Rate Calculator
      </Heading>
    </Flex>
  )
}
