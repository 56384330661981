import { types } from 'mobx-state-tree'

export const MatchConfigsModel = types.model('MatchConfigsModel', {
  id: types.string,
  maxOvers: types.maybeNull(types.number),
  ballsPerOver: types.maybeNull(types.optional(types.number, 6)),
  bowlerConsecutiveOvers: types.maybeNull(types.optional(types.boolean, false)),
  noBallRuns: types.maybeNull(types.optional(types.number, 1)),
  maxBattingReviewsPerInnings: types.maybeNull(types.optional(types.number, 0)),
  maxBowlingReviewsPerInnings: types.maybeNull(types.optional(types.number, 0)),
})

export default MatchConfigsModel
